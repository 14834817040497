import React from "react"
import Navbar from "./Navbar"
import "../assets/css/main.css"
import "../assets/css/modal.css"
import { ContextProvider } from "../context/Context"
import Footer from "./Footer"
import Sidebar from "./Sidebar"
const Layout = ({ children }) => {
  return (
    <ContextProvider>
      <Navbar />
      <Sidebar />
      <main>{children}</main>
      <Footer />
    </ContextProvider>
  )
}

export default Layout
