import React, { useEffect } from "react"
import { FaTelegram } from "react-icons/fa"
import ModalTelegram from "./ModalTelegram"
const Footer = () => {
  const telegramToggle = () => {
    const modalTelegram = document.querySelector(".modal-telegram")
    modalTelegram.classList.toggle("modal-telegram__active")
  }
  return (
    <section className="footer">
      <p className="footer-link" onClick={telegramToggle}>
        Turner &copy; <span>{new Date().getFullYear()}</span>
        <span>
          {/* <a
            href="https://telegram.me/turner_cola"
            target="_blank"
            rel="noreferrer" 
          > */}
          <FaTelegram className="telegram" />
          <ModalTelegram telegramToggle={telegramToggle} />
          {/* </a> */}
        </span>
      </p>
    </section>
  )
}

export default Footer
