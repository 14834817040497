import React from "react"

const ModalTelegram = ({ telegramToggle }) => {
  return (
    <div className="modal-telegram">
      <div className="modal-telegram__container">
        <button onClick={telegramToggle}>
          <p onClick={telegramToggle}>
            Есть вопросы? Пишите в Телеграме!
            <br />
            <span>@turner_cola</span>
          </p>
        </button>
      </div>
    </div>
  )
}

export default ModalTelegram
