import { Link } from "gatsby"
import React from "react"
import { useGalleryContext } from "../context/Context"

const Sidebar = () => {
  const { sidebarToggle } = useGalleryContext()
  const sidebarClose = () => {
    if (document.querySelector(".shade-active")) sidebarToggle()
  }
  return (
    <>
      <div className="shade" type="button" onClick={sidebarClose} />
      <section className="sidebar">
        <Link
          to="/"
          className="sidebar-link"
          activeClassName="active-link"
          onClick={sidebarToggle}
        >
          Главная
        </Link>
        <Link
          to="/artworks"
          className="sidebar-link"
          activeClassName="active-link"
          onClick={sidebarToggle}
        >
          Картины
        </Link>
        <Link
          to="/about"
          className="sidebar-link"
          activeClassName="active-link"
          onClick={sidebarToggle}
        >
          Биография
        </Link>
        <Link
          to="/contact"
          className="sidebar-link"
          activeClassName="active-link"
          onClick={sidebarToggle}
        >
          Контакты
        </Link>
      </section>
    </>
  )
}

export default Sidebar
