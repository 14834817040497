import React, { createContext, useContext, useEffect, useState } from "react"
let imgRibbon,
  scroll = {},
  sidebarContorls = {},
  len = 0

const Context = createContext()
export const ContextProvider = ({ children }) => {
  const [index, setIndex] = useState(0)
  const [modalToggle, setModalToggle] = useState(false)
  const [picturePath, setPicturePath] = useState("")
  const [toggleCheckScroll, setToggleCheckScroll] = useState("")

  useEffect(() => {
    // sidebar
    sidebarContorls.sidebar = document.querySelector(".sidebar")
    sidebarContorls.sidebarBtn = document.querySelector(".nav-btn")
    sidebarContorls.sidebarLinks = document.querySelectorAll(".sidebar-link")
    sidebarContorls.shade = document.querySelector(".shade")

    // scroll
    scroll.footer = document.querySelector(".footer")
    checkScroll()
    document.addEventListener("scroll", onScrollToFooter)
  }, [])

  useEffect(() => {
    imgRibbon = Array.from(document.querySelectorAll(".slider-container__img"))
  }, [index])

  // sidebar
  const sidebarToggle = () => {
    const { sidebar, sidebarLinks, sidebarBtn, shade } = sidebarContorls
    sidebarBtn.classList.toggle("open")
    shade.classList.toggle("shade-active")
    sidebar.classList.toggle("sidebar-active")
    Array.from(sidebarLinks).forEach(link =>
      link.classList.toggle("sidebar-link__animation")
    )
    setToggleCheckScroll(() => Math.random())
  }

  // modal
  const showModal = e => {
    if (!modalToggle) {
      const picture = e.target
      const newIndex = +picture.dataset.index - 1
      setModalToggle(modalToggle => !modalToggle)
      setPicturePath(picture.getAttribute("src"))
      setTimeout(setIndex(newIndex))
    } else {
      setModalToggle(modalToggle => !modalToggle)

      if (imgRibbon && document.querySelector(".slider-container")) {
        len = -index * document.querySelector(".slider-container").offsetWidth

        imgRibbon.forEach(i => {
          i.style.transform = `translate(${len}px,0)`
          i.style.opacity = "0"
        })
        imgRibbon[index].style.opacity = "1"
      }
    }
  }

  // const setRandomPictureOnStart = () => {
  //   if (img) {
  //     newIndex = Math.floor(Math.random() * 4).
  //     // console.log(newIndex)
  //     setIndex(newIndex)
  //     len = -500 * newIndex

  //     img.forEach(i => {
  //       i.style.transform = `translate(${len}px,0)`
  //       i.style.opacity = "0"
  //       img[newIndex].style.opacity = "1"
  //     })
  //   }

  //   if (img[newIndex]) img[newIndex].style.opacity = "1"
  // }

  const fadeArrow = el => {
    el.style.opacity = "0"
    el.style.cursor = "default"
  }

  const showArrow = el => {
    el.style.opacity = "1"
    el.style.cursor = "pointer"
  }

  const showArrows = (back, forward, list) => {
    if (index === 0) fadeArrow(back)
    else showArrow(back)
    if (index === list.length - 1) fadeArrow(forward)
    else showArrow(forward)
  }

  const controlShowArrows = (action, back, forward, list) => {
    if (action === "back") {
      if (index === 1) fadeArrow(back)
      if (index === list.length - 1) showArrow(forward)
    }

    if (action === "forward") {
      if (index === list.length - 2) fadeArrow(forward)
      if (index === 0) showArrow(back)
    }
  }

  // events
  const addEvent = (event, frame, action) => {
    frame.addEventListener(event, action)
    return () => frame.removeEventListener(event, action)
  }

  // scroll
  useEffect(() => {
    scroll.footer.style.opacity = "0"
    setTimeout(showFooter)
  }, [toggleCheckScroll])

  const showFooter = () => {
    checkScroll()
    const { scrollHeight, clientHeight, footer } = scroll
    if (scrollHeight - clientHeight < 40) footer.style.opacity = "1"
  }

  const onScrollToFooter = () => {
    checkScroll()
    const { scrollHeight, clientHeight, scrollTop, footer } = scroll
    if (scrollHeight - clientHeight - scrollTop < 50) footer.style.opacity = "1"
    else footer.style.opacity = "0"
  }

  const checkScroll = () => {
    scroll.scrollHeight = document.documentElement.scrollHeight
    scroll.clientHeight = document.documentElement.clientHeight
    scroll.scrollTop = document.documentElement.scrollTop
  }

  // graphql
  const arrayFromQuery = data => {
    const array = data.allFile.nodes.slice(1)
    return array.sort((a, b) => {
      return a.name - b.name
    })
  }

  return (
    <Context.Provider
      value={{
        showModal,
        fadeArrow,
        showArrow,
        modalToggle,
        setModalToggle,
        picturePath,
        setPicturePath,
        index,
        setIndex,
        showArrows,
        controlShowArrows,
        sidebarToggle,
        addEvent,
        arrayFromQuery,
        showFooter,
        setToggleCheckScroll,
      }}
    >
      {children}
    </Context.Provider>
  )
}
export const useGalleryContext = () => {
  return useContext(Context)
}
