import React from "react"
import { Link } from "gatsby"
import { useGalleryContext } from "../context/Context"

const Navbar = () => {
  const { sidebarToggle, setToggleCheckScroll } = useGalleryContext()

  return (
    <nav className="navbar">
      <div className="nav-center">
        <div className="nav-header">
          <Link to="/" onClick={() => setToggleCheckScroll("1")}>
            <h3 className="nav-header__text">Николай Крутов</h3>
          </Link>
          <button className="nav-button" onClick={sidebarToggle}>
            <div className="nav-btn">
              <span></span>
              <span></span>
              <span></span>
              <span></span>
            </div>
          </button>
        </div>
        <div className="nav-links show-links">
          <Link
            to="/"
            className="nav-link"
            activeClassName="active-link"
            onClick={() => setToggleCheckScroll("1")}
          >
            Главная
          </Link>
          <Link
            onClick={() => setToggleCheckScroll("2")}
            to="/artworks"
            className="nav-link"
            activeClassName="active-link"
          >
            Картины
          </Link>
          <Link
            to="/about"
            className="nav-link"
            activeClassName="active-link"
            onClick={() => setToggleCheckScroll("3")}
          >
            Биография
          </Link>
          <Link
            to="/contact"
            className="nav-link"
            activeClassName="active-link"
            onClick={() => setToggleCheckScroll("4")}
          >
            Контакты
          </Link>
          <Link to="/test" className="nav-link lang-link">
            <button>En</button> / <button>Ru</button>
          </Link>
        </div>
      </div>
    </nav>
  )
}

export default Navbar
